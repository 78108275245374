import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`1 Mile Run for time.`}</p>
    <p>{`Rest then,`}</p>
    <p>{`1000m Bike Erg`}</p>
    <p>{`70-Situps`}</p>
    <p>{`750m Row`}</p>
    <p>{`60-Stretch Crunch`}</p>
    <p>{`.75 Mile Recumbent Bike`}</p>
    <p>{`50-Full Crunch`}</p>
    <p>{`500m Ski Erg`}</p>
    <p>{`40-Superman Extensions (alternate arm/leg raise, 20/side)`}</p>
    <p>{`.5 Mile Assault Bike`}</p>
    <p>{`30-K2E’s`}</p>
    <p>{`50-Double Unders (150 Single Unders)`}</p>
    <p>{`20-GHD Situps`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      